<template>
    <div class="TopTabbar">
        <div class="LeftIcon" @click="back()">
            <i class="iconfont iconfanhui" v-if="left"></i>
        </div>
        <div class="img" v-if="!hastitle">
            <img  :src="$store.state.logo" v-if="shop_id" />

            <img src="../assets/img/logo.png" alt v-else/>
        </div>
        <p class="topTitle" v-if="hastitle">{{ title }}</p>
        <div v-if="this.Mobile" class="RightIcon">
            <slot name="right"></slot>
            <i
                class="iconfont iconsousuo1"
                v-if="rightSreach"
                @click="Jump()"
            ></i>
        </div>
        <div v-if="this.Mobile == false" class="RightIcon">
            <slot name="right"></slot>
            <!-- <i class="iconfont iconsousuo1" v-if="rightSreach" @click="Jump()"></i> -->
        </div>
    </div>
</template>
<script>
import {
    getLocalStorage,
    setLocalStorage,
    removeLocalStorage,
} from "@/utils/storage.js";
export default {
    props: {
        left: {
            // 必须提供字段
            default: true,
        },
        rightSreach: {
            default: true,
        },
        hastitle: {
            default: false,
        },
        title: {
            default: "",
        },
    },
    data() {
        return {
            isfull: false,
            Mobile: null,
            shop_id: getLocalStorage("shop_id")

        };
    },
    created() {
        this.isMobile();
    },
    methods: {
        isMobile() {
            if (screen.height == 1080 && screen.width == 1920) {
                return (this.Mobile = false); // PC端
            } else {
                return (this.Mobile = true); //移动端
            }
        },
        fullsreen() {
            var de = document.documentElement;
            if (de.requestFullscreen) {
                de.requestFullscreen();
            } else if (de.mozRequestFullScreen) {
                de.mozRequestFullScreen();
            } else if (de.webkitRequestFullScreen) {
                de.webkitRequestFullScreen();
            }
            this.isfull = !this.isfull;
        },
        smallsreen() {
            var de = document;
            if (de.exitFullscreen) {
                de.exitFullscreen();
            } else if (de.mozCancelFullScreen) {
                de.mozCancelFullScreen();
            } else if (de.webkitCancelFullScreen) {
                de.webkitCancelFullScreen();
            }
            this.isfull = !this.isfull;
        },
        back() {
            this.$router.go(-1);
        },
        Jump() {
            this.$router.push("/search");
        },
    },
};
</script>
<style lang="scss" scoped>
.TopTabbar {
    display: block;
    position: fixed;
    top: 0px;
    width: 50%;
    height: 44px;
    z-index: 1;
    width: 100%;
    background: #fff;
    .img {
        width: 79.18px;
        height: 30px;
        margin: 0px auto;
        display: block;
        margin-top: 7px;
        img {
            width: 100%;
            height: 100%;
        }
    }
    .topTitle {
        text-align: center;
        font-size: 14px;
        height: 44px;
        line-height: 44px;
        margin: 0px;
    }
    .iccc {
        position: absolute;
        right: 10px;
        width: 44px;
        height: 44px;
        text-align: center;
        line-height: 44px;
        font-size: 24px;
    }
    .LeftIcon {
        padding: 0 20px;
        position: absolute;
        top: 0px;
        left: 10px;
        display: flex;
        z-index: 2;
        i {
            height: 44px;
            width: 44px;
            font-size: 24px;
            line-height: 44px;
            margin-right: 10px;
            text-align: center;
        }
    }
    .RightIcon {
        position: absolute;
        top: 0px;
        right: 10px;
        display: flex;
        z-index: 2;
        i {
            display: block;
            height: 44px;
            width: 44px;
            font-size: 24px;
            line-height: 44px;
            margin-left: 10px;
            text-align: center;
        }
    }
}
</style>
