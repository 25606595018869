<template>
  <div>
    <div class="contentWrap">
      <div class="navigation">
        <div 
        v-show="item.show"
        v-for="(item, index) in navigation" :key="index" class="navItem" @click="navGo(item.path,item.query)">
          <div class="icon">
            <i class="iconfont" :class="item.icon"></i>
          </div>
          <span>{{ item.name }}</span>
        </div>
      </div>

      <!-- <div class="swiper-wrapper">
        <swiper v-if="list.length > 0" :options="swiperOption" ref="swiperOption" class="swiperWrap">
          <swiper-slide v-for="(item, index) in list" :key="index" class="swiperItem"
            @click="Jump(item.id, item.skip_url)">
            <img :src="item.img" @click="Jump(item.id, item.skip_url)" />
            <div class="txt" @click="Jump(item.id, item.skip_url)">
              <p class="title" data-swiper-parallax="-100" data-swiper-parallax-opacity="0">
                {{ item.title }}
              </p>
              <p class="subtitle" data-swiper-parallax="-300" data-swiper-parallax-opacity="0">
                {{ item.subtitle }}
              </p>
            </div>
          </swiper-slide>

          <div class="pagWrap" slot="pagination"></div>
        </swiper>
      </div> -->
      <div
      class="swiper-container" ref="wrapper">
          <div       
              class="swiper-wrapper">
                  <div 
                  v-for="(item, index) in list" :key="index"
                  class="swiper-slide">
                      <img :src="item.img" 
                      class="product-img"
                      @click="Jump(item.id, item.skip_url)" />
                      <div class="swiper-button-prev"></div><!--左箭头。如果放置在swiper外面，需要自定义样式。-->
                      <div class="swiper-button-next"></div><!--右箭头。如果放置在swiper外面，需要自定义样式。-->
                  </div>
              <div>
          </div>
          </div>
      </div>

    </div>

    <div class="loadingbox" v-show = "showLoading">
      <img class="loadingbg" src="@/assets/img/loadingbg.jpg" alt="" />
      <img class="loadinglogo" :src="$store.state.v_loge" alt="" v-if="$store.state.v_loge" />
      <img class="loadinglogo" src="@/assets/img/loaddinglogo.png" alt="" v-else />
      <img class="loading" src="@/assets/img/loading.gif" alt="" />
      <p>正在加载内容中请稍等~</p>
    </div>


    <!-- <van-popup v-model="isShowWechat" :close-on-click-overlay="false" style="z-index: 999" :closeable="true"> -->
    <div class="wxchatLogin" v-show="isShowWechat" @click="isShowWechat = false">
      <div id="login-iframe">
        <!-- <img 
        class="login-logo"
        src="../assets/img/logo.png" alt=""> -->
      </div>
    </div>

    <div class="quit-wrapper" v-show="showQuit" @click="showQuit = false">
      <div class="quit">
        <div class="logo">
          <img :src="$store.state.v_loge" alt="" v-if="$store.state.v_loge" />
          <img  src="@/assets/img/verticalLogo.png" alt="" v-else />
        </div>
        <div class="btn" @click.stop="handleQuit">退出账号</div>
      </div>
    </div>


    <!-- </van-popup> -->
      <div class="quitBtn" v-if="isFull" @click="switchFullScreen">退出全屏</div>
		  <div class="quitBtn" v-if="!isFull" @click="switchFullScreen">全屏</div>
      <div class="quitBtn version-check" v-if="showCheckVersion"  @click="checkAppVersion">检查版本</div>
      <div class="quitBtn setup-download-percent" v-if="showCheckVersion&&downloadPercent>0&&downloadPercent<100" >下载进度 <label>{{downloadPercent+"%"}}</label></div>
      <div class="quitBtn setup-download-percent"  @click="installNewVersionApp" v-if="showCheckVersion&&downloadPercent==100" >安装已下载</div>
      
  </div>
</template>
<script
  type="text/javascript"
  src="http://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js"
></script>
<script>
import { Progress } from "vant";
import Vue from "vue";
Vue.use(Progress);
import { activeHome, shoppCarList,getWxLoginUrl,getDetail } from "../api/http.js";
import layout from "@/components/layout.vue";
// import { swiper, swiperSlide } from "vue-awesome-swiper";
import {
    getLocalStorage,
    setLocalStorage,
    removeLocalStorage,
} from "@/utils/storage.js";
import wxlogin from "vue-wxlogin";
import Swiper from 'swiper'
export default {
    components: {
        layout,
        wxlogin,

    },
    data() {
        return {
          downloadPercent:1,
            isFull: false,
            list: [],
            swiperOption: {
                // autoplay: {
                //     delay: 3000,
                //     disableOnInteraction: false,
                // },
                // speed: 1000,
                // parallax: true,
                // loop: true,

                // pagination: {
                //     el: ".pagWrap",
                //     clickable: false,
                //     type: "progressbar",
                //     progressbarFillClass: "swpagination",
                //     renderProgressbar: function (progressbarFillClass) {
                //         return (
                //             '<span class="' +
                //             progressbarFillClass +
                //             '" ></span>'
                //         );
                //     },
                // },

                effect: "cube",
                loop: true,
                autoplay: true,
                touchRatio: 1,
                disableOnInteraction: false,
                allowTouchMove: false,
                shortSwipes: true,
                threshold: 10,
                hiddenClass: "my-button-hidden",
                touchMoveStopPropagation: true,
                // followFinger:false,
                freeMode: true,
            },
            shop_id: getLocalStorage("shop_id"),
            swiper:null,
            navigation: [
                {
                    icon: "iconshouye2",
                    name: "首页",
                    path: "/",
                    show:true,
                },
                {
                    icon: "iconfenlei2",
                    name: "分类",
                    path: "/category2",
                    show:true,
                },
                {
                    icon: "iconwode1",
                    name: "3D定制",
                    path: "/cash",
                    show:getLocalStorage("threeSwitch") == 'on',
                    query:{
                      isCustom:true
                    }
                },
                {
                    icon: "icontuijian1",
                    name: "推荐",
                    path: "/cash",
                    show:true,

                },
                {
                    icon: "iconshipin2",
                    name: "视频",
                    path: "/video",
                    show:true,
                },
                {
                    icon: "iconlin-coppertongbi",
                    name: "今日金价",
                    path: "/goldPrice",
                    show:true,
                },
                {
                    icon: "iconwode2",
                    name: "我的",
                    path: "/my",
                    show:true,
                },
            ],
            wxlogin: {},
            isShowWechat:false,
            href:null,
            showQuit:false
            

        };
    },
  
    created() {
     
        

    },
    beforeMount(){

      if (getLocalStorage("shop_id")) {
            getDetail(getLocalStorage("shop_id")).then((res) => {
                if(!res){
                    return
                }

                let isVideoOff = res.data.detail.is_video_off;
                if(isVideoOff == '1'){
                  this.navigation.splice(4,1)
                }

            })
      }
        
    },
    mounted() {
        // document.querySelector(".nav_top").style.opacity = 0;
        // document.querySelector(".imgName").style.opacity = 0;
        // document.querySelectorAll(".van-tabbar-item").forEach(item => {
        //   item.style.opacity = 0;
        // });
        this.activeHome();
        const s = document.createElement("script");
        s.type = "text/javascript";
        s.src = "https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js";
        const wxElement = document.body.appendChild(s);
        this.getWxCode();

        if(process.env.VUE_APP_ISAPP=='true'){
          this.isFull = true

          window.electronAPI.onSetupDownloadPetcentUpdate((_event, info) => {
            //参数1 网速 ,参数2 百分比
            console.log("onSetupDownloadPetcentUpdate", _event, info);
            this.downloadPercent = info.percent;
          });

        }

        if(this.$store.state.logo){
          let element = document.getElementById('login-iframe');
          element.style.backgroundImage = 'url(' + this.$store.state.logo + ')';
        }

        

    },
    activated() {
        if (localStorage.getItem("login_token")) {
            let token = localStorage.getItem("login_token");
            shoppCarList(token).then((res) => {
                this.$store.commit("cart", res.data.goods_list.length);
            });
        } else {
            this.$store.commit("cart", 0);
        }
    },
    computed:{
      showLoading(){
        return process.env.VUE_APP_ISAPP!='true'
      },

      showCheckVersion(){
        return process.env.VUE_APP_ISAPP=='true'
      }
    },
    methods: {
      checkAppVersion(){
        if(window.electronAPI){
          window.electronAPI.checkAppVersionForUpdate()
        }
      },
      installNewVersionApp(){
        if(window.electronAPI){
          window.electronAPI.installUpdate()
        }
      },
      enterFullScreen() {
        document.documentElement.webkitRequestFullScreen();
      },
      exitFullScreen() {
        let el = document;
        let cfs =
          el.cancelFullScreen ||
          el.mozCancelFullScreen ||
          el.msExitFullscreen ||
          el.webkitExitFullscreen ||
          el.exitFullscreen;
        if (cfs) {
          // typeof cfs != "undefined" && cfs
          cfs.call(el);
        } else if (typeof window.ActiveXObject !== 'undefined') {
          // for IE，这里和fullScreen相同，模拟按下F11键退出全屏
          let wscript = new ActiveXObject('WScript.Shell');
          if (wscript != null) {
            wscript.SendKeys('{F11}');
          }
        }
      },
      switchFullScreen() {

        if(process.env.VUE_APP_ISAPP=='true'){
        
          if (this.isFull) {
            window.electronAPI.quitFullScreen();
            this.isFull = !this.isFull
          } else {
            window.electronAPI.fullScreen();
            this.isFull = !this.isFull
          }
          
        }else{
          if (this.isFull) {
            this.exitFullScreen();
            this.isFull = !this.isFull
          } else {
            this.enterFullScreen();
            this.isFull = !this.isFull
          }
        }
        
      },
      initSwiper(){
            this.swiper = new Swiper('.swiper-container',{
                effect : 'cube',
                loop:true,
                centeredSlides:true,
                allowTouchMove: false,
                // navigation: {
                //     nextEl: '.swiper-button-next',
                //     prevEl: '.swiper-button-prev',
                // },
                // width: window.innerWidth,
                navigation: {
                  prevEl: '.swiper-button-next',
                  nextEl: '.swiper-button-prev',
                },
                autoplay:true,
                coverflowEffect: {
                    rotate: 10,
                    stretch: 10,
                    depth: 5,
                    modifier: 2,
                    // slideShadows : true
                },
                
              })
        },
        activeHome() {
            activeHome(this.shop_id ? this.shop_id : "")
                .then((res) => {
                    let data = res.data.data;
                    this.list = [];
                    for (let i in data) {
                        for (let j in data[i].image) {
                            this.list.push({
                                id: data[i].pad_ad_id,
                                img: data[i].image[j].file_path,
                                title: data[i].name,
                                subtitle: data[i].subheading,
                                skip_url: data[i].skip_url,
                            });
                        }
                    }
                    this.transition();
                    
                    this.$nextTick(()=>{
                      this.initSwiper();
                    })
                })
                .catch(() => {
                    this.transition();
                });
        },
        transition() {
            setTimeout(() => {
                document.querySelector(".loadingbox").style.opacity = 0;
                setTimeout(() => {
                    document.querySelector(".loadingbox").style.display =
                        "none";
                }, 300);
                // setTimeout(() => {
                //   document.querySelector(".nav_top").style.opacity = 1;
                // }, 500);
                // setTimeout(() => {
                //   document.querySelector(".imgName").style.opacity = 1;
                // }, 1000);
                setTimeout(() => {
                    let time = 200;
                    document
                        .querySelectorAll(".van-tabbar-item")
                        .forEach((item) => {
                            setTimeout(() => {
                                item.style.opacity = 1;
                            }, time);
                            time += 100;
                        });
                }, 1500);
            }, 1500);
            
        },

        Jump(id, url) {
            if (url != "") {
                this.$router.push(url);
            } else {
                this.$router.push({
                    path: "/videodetail",
                    query: {
                        id: id,
                    },
                });
            }
        },
        changeIndex(current, total) {
            this.progress = (current / total) * 100;
        },
        navGo(path,query) {
          if(path==='/my'){
            
           
            if(process.env.VUE_APP_ISAPP=='true'){
              if(localStorage.getItem("login_token")){
                this.showQuit = true
              }else{
                window.electronAPI.showWeiXinLogin()
              }
              
            }else{
              if(localStorage.getItem("login_token")){
                this.showQuit = true
              }else{
                this.isShowWechat = true
              }
            }
            
          }else if(path==='/'){
            return
          }else{
            this.$router.push({
              path,
              query
            });

          }
        },
        getWxCode() {
            getWxLoginUrl().then((res) => {
                // if (this.Mobile) {
                //     this.href =
                //         "LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDE4MHB4O30KLmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5OiBub25lO30KLmltcG93ZXJCb3ggLmluZm8ge3dpZHRoOiAxODBweDt9Ci5pbXBvd2VyQm94IC5pbmZvIHAge2ZvbnQtc2l6ZToxMHB4O30KLnN0YXR1c19pY29uIHtkaXNwbGF5OiBub25lfQouaW1wb3dlckJveCAuc3RhdHVzX2ZhaWwsLmltcG93ZXJCb3ggLnN0YXR1c19zdWNje3dpZHRoOjIwMHB4O3BhZGRpbmc6MDt9Ci5pbXBvd2VyQm94IC5zdGF0dXMge3RleHQtYWxpZ246IGNlbnRlcjt9";
                // } else {
                    // $("#login-iframe").css({
                    //     marginTop: "30px",
                    // });
                    this.href =
                        "LmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5OiBub25lO30KLmltcG93ZXJCb3ggLmluZm8gcCB7Zm9udC1zaXplOjEwcHg7fQouc3RhdHVzX2ljb24ge2Rpc3BsYXk6IG5vbmV9Ci5zdGF0dXNfc3VjYywuc3RhdHVzX2ZhaWx7aGVpZ2h0OjIwMHB4fQouaW1wb3dlckJveCAuaWNvbjM4X21zZ3tkaXNwbGF5OmJsb2NrO21hcmdpbjowIGF1dG87fQouaW1wb3dlckJveCAuc3RhdHVzIHt0ZXh0LWFsaWduOiBjZW50ZXI7fQ==";
                // }
                let path = encodeURIComponent(
                    window.location.origin + '/#/wechat'
                );
                
                // let path = encodeURIComponent(
                //   'https://large.shanheji.com.cn'
                // );
                var obj = new WxLogin({
                    id: "login-iframe", // 需要显示的容器id
                    appid: res.data.appid, // 公众号appid wx*******
                    scope: "snsapi_login", // 网页默认即可
                    redirect_uri: path, // 授权成功后回调的url
                    state: res.data.state, // 可设置为简单的随机数加session用来校验
                    style: "black", // 提供"black"、"white"可选。二维码的样式
                    // href: "data:text/css;base64," + this.href,
                });

            });
        },
        handleQuit(){
          localStorage.removeItem("login_token");
          localStorage.removeItem("user_type");

          if(process.env.VUE_APP_ISAPP=='true'){
            this.showQuit = false;
          }else{
            this.$router.go('/');
          }
          

          
        }
    },
};
</script>
<style lang="scss" scoped>
#login-iframe {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background-color: #fff;
  border-radius: 5px;
  width: 500px;
  //height: 500px;
  background-image: url("../assets/img/logo.png");
  background-repeat: no-repeat;
  background-position: center 20%;
  padding-bottom: 50px;
  box-sizing: border-box;
}

.wxchatLogin {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  background-color: #000;
  opacity: 1 !important;
}

.quit-wrapper {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  background-color: #000;

  .quit {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 5px;
    width: 500px;
    height: 500px;
  }

  .logo {
    width: 130px;
    height: 130px;
    border-radius: 50%;
    background-color: #3B271D;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 50px;
    }
  }

  .btn {
    width: 350px;
    height: 80px;
    border-radius: 5px;
    line-height: 80px;
    text-align: center;
    color: #fff;
    background-color: #3B271D;
    margin-top: 80px;
  }
}

.loadingbox {
  transition: 0.5s;
  opacity: 1;
  position: fixed;
  width: 100%;
  height: 100%;
  background: #fff;
  top: 0;
  z-index: 99999;

  .loadinglogo {
    position: absolute;
    top: 43%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
  }

  .loadingbg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .loading {
    width: 30px;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  p {
    font-size: 15px;
    position: absolute;
    top: 63%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.contentWrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  // overflow: hidden;
  height: 100vh;
  background-color: #51351c;
  // padding-top: 15px;

  .navigation {
    position: absolute;
    right: 0;
    top: 15%;
    width: 150px;
    height: 850px;
    padding:50px 0 ;
    background: url("../assets/img/homeBg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    .navItem {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .icon {
        width: 80px;
        height: 80px;
        // line-height: 80px;
        // text-align: center;
        background: url("https://img3a.gemtec.ltd/largeResource/hehuanbian3.svg") no-repeat;
        background-size: 100% 100%;
        color: #ffd68b;
        // margin-bottom: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        .iconfont {
          font-size: 28px;
        }
      }

      span {
        font-size: 12px;
        color: #ffffff;
      }
    }
  }
  // 新swiper
  .swiper-container{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #51351c;
    
    
    .swiper-wrapper{
      height: 95%;
   
      .swiper-slide{
        // height: 100%;
        // background: red;
        // 
        display: flex;
        justify-content: center;
        position: relative;
        .swiper-button-prev{
          height: 200px;
          width: 200px;
          position: absolute;
          left: 0;
          top:85%;
          background-image: url("../assets/img/pre.png") ;
          background-position: center center;
          background-repeat: no-repeat;
          background-size: 80px 80px;
          &::after{
                content: none;
            }
        }
      .swiper-button-next{
        height: 200px;
        width: 200px;
        position: absolute;
        // right: 0;/
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 80px 80px;
        background-image: url("../assets/img/next.png");
        top:85%;
        &::after{
                content: none;
            }
      }
        .product-img{
          height: 100%;
          width: 95%;
          border-radius: 5px;
        }

      }
    }

  }

  // .swiper-wrapper{
  //   height:100%;
  // }
  // .swiperWrap {
  //   height: 100%;
  //   width: 100%;

  //   // position: fixed;
  //   .swiperItem {
  //     position: relative;
  //     height: 100%;

  //     img {
  //       height: 90%;
  //       width: 95%;
  //       position: relative;
  //       top: 0;
  //       left: 50%;
  //       transform: translateX(-50%);
  //       border-radius: 20px;
  //       object-fit:cover;
  //     }

  //     .txt {
  //       position: absolute;
  //       bottom: 75px;
  //       color: #fff;
  //       width: 100%;

  //       p {
  //         width: 100%;
  //         text-align: center;
  //         margin: 0px;
  //       }

  //       .title {
  //         margin-bottom: 10px;
  //         font-size: 36px;
  //         font-weight: 600;
  //       }

  //       .subtitle {
  //         font-size: 22px;
  //       }
  //     }
  //   }

  //   .pagWrap {
  //     // height: 20px;
  //     background: #d6d6d6;
  //     height: 5px !important;
  //     width: 300px !important;
  //     position: fixed;
  //     bottom: 50px !important;
  //     z-index: 10;
  //     left: 50% !important;
  //     top: initial !important;
  //     transform: translateX(-50%);
  //   }
  // }
}
</style>
<style>
.swpagination {
  background: #c9a864;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: left top;
  -ms-transform-origin: left top;
  transform-origin: left top;
}
.quitBtn {
		position: absolute;
		bottom: 2%;
		left: 2%;
		z-index: 999999;
		border: 1px solid #a4a6ab;
		width: 0.5rem;
		height: 0.1rem;
		line-height: 0.1rem;
		text-align: center;
		color: #a4a6ab;
		font-size: 0.08rem;
		border-radius: 10%;
	}
  .version-check{
    left: 8% !important;
  }
  .setup-download-percent{
    left: 14% !important;
    width: 0.6rem !important;
  }
</style>
