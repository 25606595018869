<template>
  <div class="screen-wrpper">
    <swiper
      v-if="imglist.length>0"
      :options="swiperOption"
      :style="'hight:'+viewheight+'px;width:'+viewheight+'px'"
      ref="swiperOption"
      class="screen"
      @click="closeScreen()"
    >
      <swiper-slide v-for="(item,index) in imglist" :key="index" class="screenItem">
        <img
          :src="item.img| thumbnail(800,800)"
          :style="'height:'+viewheight+'px;width:'+viewheight+'px'"
        />
        <div class="text_wrap">
          <div class="title" data-swiper-parallax="-1000" data-swiper-parallax-opacity="0">
            <p>{{item.name}}&nbsp;&nbsp;|&nbsp;&nbsp;{{item.weight}}克</p>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>
<script>
import { Swipe, SwipeItem } from "vant";
import { screen } from "../api/http.js";
import Vue from "vue";
Vue.use(Swipe).use(SwipeItem);
export default {
  data() {
    return {
      imglist: [],
      swiperOption: {
        autoplay: {
          delay: parseInt(localStorage.getItem("screenTime"))*1000 || 3000,
          disableOnInteraction: false
        },
        speed: 1000,
        parallax: true,
        loop: true,
        pagination: {
          el: ".pagWrap",
          clickable: false
        }
      }
    };
  },
  prop: [],
  created() {
    this.loadImg();
  },
  activated() {},
  computed: {
    viewheight() {
      return this.$store.state.screenHeight;
    }
  },
  methods: {
    loadImg() {
      screen().then(res => {
        console.log(res.data);
        let data = res.data;
        for (let i in data) {
          this.imglist.push({
            img: data[i].image[0].file_path,
            name: data[i].goods_name,
            weight: (data[i].sku[0].goods_weight).toFixed(2)
          });
        }
      });
    },
    closeScreen() {
      this.$emit("closeScreen");
    }
  }
};
</script>
<style lang="scss" scoped>
.swiper-container{
    height: 99vh;
}
.screen-wrpper{
    overflow: hidden;
    height: 99vh;
}
.screen {
    overflow: hidden;
  .screenItem {
    position: relative;
    background: #000;
    overflow: hidden;
    .text_wrap {
      position: absolute;
      top: 30px;
      width: 100%;
      text-align: center;
      z-index: 2;
      .title {
        width: 500px;
        margin: 0px auto;
        padding: 0px 20px;
        height: 60px;
        // background: #2c2c2c;
        overflow: hidden;
        p {
          height: 44px;
          width: 446px;
          margin: 8px auto;
          line-height: 44px;
          font-size: 16px;
          text-align: center;
          background: url("../assets/img/bordertop.png");
          background-size: 100%100%;
          color: #d6ad58;
        }
      }
    }
  }
}
</style>
<style>
.screen {
  overflow: hidden;
}
</style>
