function pluralize(time, label) {
  if (time === 1) {
    return time + label;
  }
  return time + label + 's';
}

export function timeAgo(time) {
  const between = Date.now() / 1000 - Number(time);
  if (between < 3600) {
    return pluralize(~~(between / 60), ' minute');
  } else if (between < 86400) {
    return pluralize(~~(between / 3600), ' hour');
  } else {
    return pluralize(~~(between / 86400), ' day');
  }
}

export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null;
  }

  if ((time + '').length === 10) {
    time = +time * 1000;
  }

  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
  let date;
  if (typeof time === 'object') {
    date = time;
  } else {
    date = new Date(parseInt(time));
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };

  return format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key];
    if (key === 'a') return ['一', '二', '三', '四', '五', '六', '日'][value - 1];
    if (result.length > 0 && value < 10) {
      value = '0' + value;
    }
    return value || 0;
  });
}

export function formatTime(time, option) {
  let d = null;
  if (/^\d$/.test(time)) {
    time = +time * 1000;
    d = new Date(time);
  } else {
    let arr = time.split(/[- : \/]/);
    d = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]);
  }

  const now = Date.now();

  const diff = (now - d) / 1000;

  if (diff < 30) {
    return '刚刚';
  } else if (diff < 3600) { // less 1 hour
    return Math.ceil(diff / 60) + '分钟前';
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前';
  } else if (diff < 3600 * 24 * 2) {
    return '1天前';
  }
  if (option) {
    return parseTime(time, option);
  } else {
    return (d.getMonth() + 1) + '月' + d.getDate() + '日' + d.getHours() + '时' + d.getMinutes() + '分';
  }
}

/* 数字 格式化*/
export function nFormatter(num, digits) {
  const si = [
    {value: 1E18, symbol: 'E'},
    {value: 1E15, symbol: 'P'},
    {value: 1E12, symbol: 'T'},
    {value: 1E9, symbol: 'G'},
    {value: 1E6, symbol: 'M'},
    {value: 1E3, symbol: 'k'}
  ];
  for (let i = 0; i < si.length; i++) {
    if (num >= si[i].value) {
      return (num / si[i].value + 0.1).toFixed(digits).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[i].symbol;
    }
  }
  return num.toString();
}

export function html2Text(val) {
  const div = document.createElement('div');
  div.innerHTML = val;
  return div.textContent || div.innerText;
}

export function toThousandsFilter(num) {
  return (+num || 0).toString().replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','));
}

// import ImgSrc from '@/assets/ImgSrc';
let IMG_DEFAULT = require('../assets/img/def.jpg')
/**
 * 七牛裁剪
 imageView2/<mode>
 /w/<LongEdge>
 /h/<ShortEdge>
 /format/<Format>
 /interlace/<Interlace>
 /q/<Quality>
 /ignore-error/<ignoreError>
 */
// console.log(IMG_DEFAULT)
export function thumbnail(src, w = 60, h = 60, defaultImg = IMG_DEFAULT, option = {}) {
  if (!src) return defaultImg || '';

  let options = {
    mode: 1,
    w,
    h,
    interlace: 1,
    'ignore-error': 1,
    ...option // 这里没对参数进行限制，使用时应该遵循七牛的接口
  };

  let mode = options.mode;
  delete options.mode;

  if (!w) delete options.w;
  if (!h) delete options.h;

  let optionString = Object.keys(options).reduce((str, key) => {
    return str + '/' + key + '/' + options[key];
  }, '');


  return `${src}?imageView2/${mode}` + optionString;
}
