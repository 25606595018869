<template>
  <div>
    <template v-if="hasBottom">
      <template>
        <!-- <TopTabbar :left="false" v-if="!top" /> -->
        <slot name="head" v-if="top" />
      </template>
      <div class="content_1" >
        <slot name="content" />
      </div>
      <template>
        <Tabbar v-if="!bottom" />
        <slot name="bottom" v-if="bottom" />
      </template>
    </template>
    <template v-if="!hasBottom">
      <template>
        <TopTabbar :left="false" v-if="!top" />
        <slot name="head" v-if="top" />
      </template>
      <div class="content_2" :style="'height:'+windowsHight+'px'">
        <slot name="content" />
      </div>
    </template>
  </div>
</template>
<script>
import Tabbar from "@/components/Tabbar.vue";
import TopTabbar from "@/components/TopTabbar.vue";
export default {
  components: {
    Tabbar,
    TopTabbar
  },
  props: {
    hasBottom: {
      // 必须提供字段
      default: true
    },
    top: {
      // 必须提供字段
      default: false
    },
    bottom: {
      // 必须提供字段
      default: false
    }
  },
  data() {
    return {
      windowsHight: document.documentElement.clientHeight,
      timer: false
    };
  },
  watch: {},
  mounted() {
    window.onresize = () => {
      return (() => {
        this.windowsHight = document.documentElement.clientHeight;
        this.$store.commit("windowScreen", {
          screenHeight: document.documentElement.clientHeight,
          screenWidth: document.documentElement.clientWidth
        });
      })();
    };
  },
};
</script>
<style lang="scss" scoped>
.head {
}
.content_1 {
  width: 100%;
  overflow-x: hidden;
  padding-top: 44px;
  box-sizing: border-box;
  padding-bottom: 51px;
}
.content_2 {
  width: 100%;
  overflow-x: hidden;
  padding-top: 44px;
  box-sizing: border-box;
}
.footer {
}
</style>
