<template>
    <div class="nav_top">
        <div class="leftTabbar">
            <img class="imgName" :src="$store.state.logo" v-if="shop_id" />
            <img class="imgName" src="../assets/img/logo.png" alt v-else />
        </div>
        <van-tabbar
            v-if="this.Mobile == '/category2'"
            v-model="active"
            active-color="#9a7d4a"
            style="top: 8px; bottom: 1px; width: 35%; left: 65%"
        >
            <van-tabbar-item
                v-for="(item, index) in list2"
                :key="index"
                :to="item.path"
                :class="item.icon"
                :badge="item.name == '购物车' ? cartNum : ''"
                >{{ item.name }}</van-tabbar-item
            >
        </van-tabbar>
        <van-tabbar
            v-if="this.Mobile == '/category'"
            v-model="active"
            active-color="#9a7d4a"
            style="top: 5px; bottom: 1px; width: 35%; left: 65%"
        >
            <van-tabbar-item
                v-for="(item, index) in list"
                :key="index"
                :to="item.path"
                :class="item.icon"
                :badge="item.name == '购物车' ? cartNum : ''"
                >{{ item.name }}</van-tabbar-item
            >
        </van-tabbar>
    </div>
</template>
<script>
import { LoopPingPong } from "three";
import $ from "jquery";
import {
    getLocalStorage,
    setLocalStorage,
    removeLocalStorage,
} from "@/utils/storage.js";
export default {
    name: "Tabbar",
    data() {
        return {
            active: 0,
            replace: true,
            list: [
                {
                    name: "首页",
                    icon: "iconfont iconshouye2 ic",
                    path: "/",
                },
                {
                    name: "分类",
                    icon: "iconfont iconfenlei2 ic",
                    // path: "/category"
                    path: this.isMobile(),
                },
                {
                    name: "推荐",
                    icon: "iconfont icontuijian1 ic",
                    path: "/cash",
                },
                {
                    name: "视频",
                    icon: "iconfont iconshipin2 ic",
                    path: "/video",
                },
                {
                    name: "购物车",
                    icon: "iconfont icongouwuche1 ic",
                    path: "/shoppingcart",
                },
                {
                    name: "我的",
                    icon: "iconfont iconwode2 ic",
                    path: "/my",
                },
                {
                    name: "搜索",
                    icon: "iconfont iconsousuo ic",
                    path: "/search",
                },
            ],
            list2: [
                {
                    name: "首页",
                    icon: "iconfont iconshouye2 ic",
                    path: "/",
                },
                {
                    name: "分类",
                    icon: "iconfont iconfenlei2 ic",
                    // path: "/category"
                    path: this.isMobile(),
                },
                {
                    name: "推荐",
                    icon: "iconfont icontuijian1 ic",
                    path: "/cash",
                },
                {
                    name: "视频",
                    icon: "iconfont iconshipin2 ic",
                    path: "/video",
                },
                {
                    name: "购物车",
                    icon: "iconfont icongouwuche1 ic",
                    path: "/shoppingcart",
                },
                {
                    name: "我的",
                    icon: "iconfont iconwode2 ic",
                    path: "/my",
                },
            ],
            Mobile: null,
            cartNum: 0,
            shop_id: getLocalStorage("shop_id"),
        };
    },
    created() {
        this.isMobile();
        if (sessionStorage.getItem("vuex")) {
            this.cartNum = JSON.parse(sessionStorage.getItem("vuex"));
            this.cartNum = this.cartNum.cartNum;
        }
        if (this.cartNum == 0) {
            this.cartNum = null;
        }
        let arr = [
            "/",
            this.isMobile(),
            "/cash",
            "/video",
            "/shoppingcart",
            "/my",
        ];
        if (arr.indexOf(this.$route.path) > -1) {
            this.active = arr.indexOf(this.$route.path);
        }
        if (this.$store.state.is_video_off === 1) {
            this.list = [
                {
                    name: "首页",
                    icon: "iconfont iconshouye2 ic",
                    path: "/",
                },
                {
                    name: "分类",
                    icon: "iconfont iconfenlei2 ic",
                    // path: "/category"
                    path: this.isMobile(),
                },
                {
                    name: "推荐",
                    icon: "iconfont icontuijian1 ic",
                    path: "/cash",
                },
                // {
                //   name: "视频",
                //   icon: "iconfont iconshipin2 ic",
                //   path: "/video"
                // },
                {
                    name: "购物车",
                    icon: "iconfont icongouwuche1 ic",
                    path: "/shoppingcart",
                },
                {
                    name: "我的",
                    icon: "iconfont iconwode2 ic",
                    path: "/my",
                },
                {
                    name: "搜索",
                    icon: "iconfont iconsousuo ic",
                    path: "/search",
                },
            ];
            this.list2 = [
                {
                    name: "首页",
                    icon: "iconfont iconshouye2 ic",
                    path: "/",
                },
                {
                    name: "分类",
                    icon: "iconfont iconfenlei2 ic",
                    // path: "/category"
                    path: this.isMobile(),
                },
                {
                    name: "推荐",
                    icon: "iconfont icontuijian1 ic",
                    path: "/cash",
                },
                // {
                //   name: "视频",
                //   icon: "iconfont iconshipin2 ic",
                //   path: "/video"
                // },
                {
                    name: "购物车",
                    icon: "iconfont icongouwuche1 ic",
                    path: "/shoppingcart",
                },
                {
                    name: "我的",
                    icon: "iconfont iconwode2 ic",
                    path: "/my",
                },
            ];
            let arr = ["/", this.isMobile(), "/cash", "/shoppingcart", "/my"];
            if (arr.indexOf(this.$route.path) > -1) {
                this.active = arr.indexOf(this.$route.path);
            }
        }
    },

    mounted() {
        // if (this.Mobile == "/category2") {
        //   $(".nav_top")
        //     .css({
        //       height: "107px",
        //       display: "flex",
        //       alignItems: "center"
        //     })
        //     .children(".van-hairline--top-bottom")
        //     .css({
        //       top: "9px"
        //     });
        // }
    },
    activated() {
        let arr = [
            "/",
            this.isMobile(),
            "/cash",
            "/video",
            "/shoppingcart",
            "/my",
        ];
        if (arr.indexOf(this.$route.path) > -1) {
            this.active = arr.indexOf(this.$route.path);
        }
        if (this.$store.state.is_video_off === 1) {
            let arr = ["/", this.isMobile(), "/cash",  "/shoppingcart", "/my"];
            if (arr.indexOf(this.$route.path) > -1) {
                this.active = arr.indexOf(this.$route.path);
            }
        }
    },
    computed: {
        sur() {
            return this.$store.state.cartNum;
        },
    },
    watch: {
        sur: {
            handler(nv, ov) {
                this.cartNum = nv;
                if (this.cartNum == 0) {
                    this.cartNum = null;
                }
            },
        },
        // $route: {
        //   handler(nv, ov) {
        //     console.log("watch");
        //     if (sessionStorage.getItem("vuex")) {
        //       this.cartNum = JSON.parse(sessionStorage.getItem("vuex")).cartNum;
        //     }
        //     if (this.cartNum == 0) {
        //       this.cartNum = null;
        //     }
        //   }
        // },
        deep: true,
    },
    methods: {
        isMobile() {
            if (screen.height == 1080 && screen.width == 1920) {
                return (this.Mobile = "/category2"); // PC端
            } else {
                return (this.Mobile = "/category"); //移动端
            }
        },
    },
};
</script>
<style lang="scss">
.van-info {
    position: absolute;
    top: -25px;
    right: -15px;
}
.nav_top {
    transition: 0.5s;
    position: fixed;
    z-index: 3;
    top: 0;
    width: 100%;
    height: 57px;
    background: #fff;
    .van-tabbar-item {
        transition: 0.5s;
    }
}
.leftTabbar {
    top: 0;
    bottom: 1px;
    width: 65%;
    right: 35%;
    // position: fixed;
    background-color: #fff;
    height: 51px;
    // z-index: 2;
}
.imgName {
    transition: 0.5s;
    padding-top: 12px;
    padding-left: 40px;
    height: 40px;
    display: block;
    //   width: 120px;
    //   object-fit: contain;
}
.imgNameL {
    transition: 0.5s;
    padding-top: 17px;
    padding-left: 40px;
    height: 30px;
    display: block;
    object-fit: contain;
    width: 110px;
}
.iconfont {
    width: 28px;
    font-size: 26px;
    line-height: 25px;
}
.ic {
    color: #888888;
}
.van-tabbar-item__text {
    /* height: 11px; */
    line-height: 11px;
    font-size: 11px;
}
.van-hairline--top-bottom {
    transition: 0.5s;
    /* height: 51px; */
}
.van-hairline--top-bottom::after,
.van-hairline-unset--top-bottom::after {
    border-width: 0 !important;
}
</style>
