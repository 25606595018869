import axios from 'axios';
// import store from '@/store';
import { Toast, Dialog } from 'vant';
import { getToken } from '@/utils/auth';
import { getLocalStorage, setLocalStorage, removeLocalStorage } from './storage.js'
import store from '@/store';

// create an axios instance

let baseURL = process.env.BASE_API || '/'
if(process.env.VUE_APP_ISAPP=='true'){
	baseURL = 'https://large.shanheji.com.cn'
}
const $http = axios.create({
	baseURL: baseURL, // api的base_url
	timeout: 100000 // request timeout
});


// request interceptor
$http.interceptors.request.use(config => {
  // Do something before request is sent
  // if (store.getters.token) {
  config.headers['Authorization'] = getToken(); // 让每个请求携带token-- ['X-Token']为自定义key 请根据实际情况自行修改
  // config.headers['token'] = getToken(); // 让每个请求携带token-- ['X-Token']为自定义key 请根据实际情况自行修改

  // }
  return config;
}, error => {
  // Do something with request error
  console.log(error); // for debug
  Promise.reject(error);
});

// respone interceptor
$http.interceptors.response.use(
  // response => response,
  /**
   * 下面的注释为通过response自定义code来标示请求状态，当code返回如下情况为权限有问题，登出并返回到登录页
   * 如通过xml$httprequest 状态码标识 逻辑可写在下面error中
   */
  response => {
    // 外部api不受下面规则约束
    if (/$http(s?):\/\//.test(response.config.url)) {
      return response;
    }

    const res = response.data
    
    // 数据异常
    if (typeof res !== 'object' && res) {
      console.log(res);// for debug
      Toast.fail({
        message: '数据异常，请重试', // error.message,
        duration: 3000
      });

      return Promise.reject({
        message: '数据异常，请重试',
        data: res
      });
    } else if (res.code === 1 || res.code === 10200) {
      return res; // 直接把有用数据返回
    }

    // 其他错误代码处理
    // 10401:非法的token; 10012:其他客户端登录了;  10014:Token 过期了;
    if (res.code === 10401 || res.code === -1) {
      // store.dispatch('LogOut').then(() => {
        localStorage.removeItem("login_token");
        localStorage.removeItem("user_type");
        if(process.env.VUE_APP_ISAPP!='true'){
          window.location.replace(`/login?r=${encodeURIComponent(window.location.href)}`);
        }
        
      // });

      // 10400:参数错误 10500:请求无法完成
    } else if (res.code === 10400 || res.code === 10500) {
      Toast.fail({
        message: res.message,
        duration: 3000
      });

      // 10403:无权限 10503:内部错误
    } else if (res.code === 10403 || res.code === 10503) {
      Dialog.alert({
        title: '注意',
        message: res.message,
        confirmButtonText: '确定'
      });
    } else if (res.code === -1) {
      Dialog.alert({
        title: '注意',
        message: "请求错误",
        confirmButtonText: '确定'
      });
    } else if (res.code === 0) {
      Toast.fail(res.msg);
    }

    return res.data

    // return Promise.reject(res);
  },
  error => {
    console.log('err' + error);// for debug
    Toast.fail({
      message: '接口异常，请重试', // error.message,
      duration: 3000
    });

    return Promise.reject({
      message: '接口异常，请重试'
    });
  });

export default $http;
