export const SCENE_ROUTER  = [
        "details",
        "customPage"
    ]
export const CUSTOM_PAGE = {
    CUSTOM_COVER:0,
    CUSTOM_PART:1,
    CUSTOM_HISTORY_CONTENT:2,
    CUSTOM_SIZE:3,
    CUSTOM_IMAGE:4,
    CUSTOM_VIDEO:5,
    CUSTOM_PAY:6,
}

export const MODEL_DATA = new Map()
MODEL_DATA.set('JQ-120002',{
  part_data:[
      {
        type:'flowing',
        objName:'word',
        rotation:{
          x:0,
          y:0,
          z:0
        },
        label:'铭文',
        introdution:'杯口一面中部墅篆书“金瓯永固”,寓意疆土、政权永固;一面“乾隆年制”四字款。',
        position:{
          x:0,
          y:-1.4,
          z:0.0
        },
        scale:{
          x:1.8,
          y:1.8,
          z:1.8
        },
        speed:0.01,
        media_url:'https://img3a.gemtec.ltd/largeResource/JQ-120002text.mp3'
      },
      {
        type:'flowing',
        objName:'figure',
        rotation:{
          x:0,
          y:-Math.PI/3,
          z:0
        },
        label:'纹路',
        introdution:'杯为圆形口，口边刻有回纹。',
        position:{
          x:0,
          y:-1.4,
          z:0
        },
        scale:{
          x:1.8,
          y:1.8,
          z:1.8
        },
        speed:0.01,
        media_url:'https://img3a.gemtec.ltd/largeResource/JQ-120002pattern.mp3'
      },
      {
        type:'hideShow',
        objName:'pear',
        rotation:{
          x:0,
          y:0,
          z:0
        },
        label:'珠宝',
        introdution:'外壁满繁缠枝花卉，花蕊以珍珠及红、蓝宝石为主',
        position:{
          x:0,
          y:0.6,
          z:0
        },
        scale:{
          x:0.9,
          y:0.9,
          z:0.9
        },
        speed:0.01,
        media_url:'https://img3a.gemtec.ltd/largeResource/JQ-120002pearl.mp3'
      },
      {
        type:'flowingHiden',
        objName:'hander',
        rotation:{
          x:0,
          y:0,
          z:0
        },
        label:'杯耳',
        introdution:'杯两侧为双立妻耳，龙头各嵌珍珠一颗。',
        position:{
          x:-1.8,
          y:-0.8,
          z:1
        },
        scale:{
          x:1.9,
          y:1.9,
          z:1.9
        },
        speed:0.01,
        media_url:'https://img3a.gemtec.ltd/largeResource/JQ-120002ear.mp3'
      },
      {
        type:'flowingHiden',
        objName:'foot',
        rotation:{
          x:0,
          y:Math.PI/10,
          z:0
        },
        label:'杯足',
        introdution:'三足皆为象首式,取“吉祥”谐音之意,象耳略小,长牙卷鼻，额顶及双目间亦嵌珠宝。',
        position:{
          x:-0.2,
          y:1.7,
          z:1
        },
        scale:{
          x:1.7,
          y:1.7,
          z:1.7
        },
        speed:0.01,
        media_url:'https://img3a.gemtec.ltd/largeResource/JQ-120002foot.mp3'
      }
    ],
  size_introduce:{
    media_url:'https://img3a.gemtec.ltd/largeResource/JQ-120002sizeData.mp3',
      label:'金瓯永固杯',
      introdution:'尺寸:高10.4cm，口径:5.6cm\n材质:金',
  },
  media_data:'https://img3a.gemtec.ltd/largeResource/JQ-120002.mp3',
})

MODEL_DATA.set('JQ-20022',{
  part_data:[
    {
    type:'partShow',
    objName:'',
    rotation:{
      x:0,
      y:0,
      z:0
    },
    label:'四马',
    introdution:'“驷马”指显贵者所乘的驾四匹马的高车。表示地位显赫。齐心协力，家族共荣共存的美好寓意。',
    position:{
      x:-0.9,
      y:0,
      z:0.0
    },
    scale:{
      x:2.2,
      y:2.2,
      z:2.2
    },
    media_url:'https://qiniu.shj.gemtec.ltd/largeResource/JQ-20022-horse4.mp3'
  },
    {
    type:'partShow',
    objName:'horse',
    rotation:{
      x:0,
      y:-0.12,
      z:0
    },
    label:'马头',
    introdution:'其中一只马的，凸显马头刻画和健壮马身肌肉线条黄金马被雕刻得栩栩如生，肌肉线条清晰，动作如生。马代表着力量、速度和毅力，它象征着勇往直前、不畏困难的精神。',
    position:{
      x:-2.65,
      y:-1.5,
      z:0.0
    },
    scale:{
      x:5,
      y:5,
      z:5
    },
    media_url:'https://qiniu.shj.gemtec.ltd/largeResource/JQ-20022-horseHead.mp3'
  },

  {
    type:'partShow',
    objName:'simatuoche2',
    rotation:{
      x:0,
      y:-Math.PI/15,
      z:0
    },
    label:'车身',
    introdution:'车身设计灵感来源于古代皇室的华丽马车。整车几何牡丹镂空花纹，复杂而不失雅致。寓意大富大贵，团结发展。',
    position:{
      x:1.2,
      y:-0.2,
      z:0.0
    },
    scale:{
      x:2,
      y:2,
      z:2
    },
    media_url:'https://qiniu.shj.gemtec.ltd/largeResource/JQ-20022-body.mp3'
  },
  {
    type:'partShow',
    objName:'wheel',
    rotation:{
      x:0,
      y:-Math.PI/15,
      z:0,
    },
    label:'车轮',
    introdution:'六叶车轮透露出无与伦比的细腻与奢华。六被认为是六六大顺等吉祥寓意，代表着顺利和圆满。',
    position:{
      x:2.6,
      y:0,
      z:0.0
    },
    scale:{
      x:4,
      y:4,
      z:4
    },
    media_url:'https://qiniu.shj.gemtec.ltd/largeResource/JQ-20022-wheel.mp3'
  },
  ],
  size_introduce:{
    media_url:'https://qiniu.shj.gemtec.ltd/largeResource/JQ-20022-size.mp3',
      label:'驷马拖车',
      introdution:'尺寸:长:190mm,宽:110mm,高:85mm\n材质:金',
  },
  is_carriage_size:true,
  media_data:'https://qiniu.shj.gemtec.ltd/largeResource/JQ-20022-horse-car.mp3',
})
