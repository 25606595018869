import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);
import createPersistedState from "vuex-persistedstate";
export default new Vuex.Store({
    state: {
        screenWidth: document.documentElement.clientWidth, //屏幕宽度
        screenHeight: document.documentElement.clientHeight, //屏幕高度
        cartNum: null,
        logo: '',
        v_loge: '',
        is_code_off: 0,
        is_video_off: 0,
        threeSwitch:'off'
    },
    mutations: {
        windowScreen: (state, payload) => {
            state.screenWidth = payload.screenWidth;
            state.screenHeight = payload.screenHeight;
        },
        cart: (state, payload) => {
            state.cartNum = payload;
        },
        setLogo: (state, payload) => {
            state.logo = payload;
        },
        setVlogo: (state, payload) => {
            state.v_loge = payload;
        },
        setCode: (state, payload) => {
            state.is_code_off = payload;
        },  
        setVideo: (state, payload) => {
            state.is_video_off = payload;
        },
        setThreeSwitch:(state, payload = 'off')=>{
            if(state.threeSwitch == 'on'){
                return
            }
            state.threeSwitch = payload
        }
    },
    getters:{
        getThreeSwitch(state){
            return state.threeSwitch
        }
    },
    actions: {},
    plugins: [
        createPersistedState({
            storage: window.sessionStorage
        })
    ]
});
