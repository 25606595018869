export function getLocalStorage(key) {
  return JSON.parse(localStorage.getItem(key));
}

export function setLocalStorage(key, data) {
  return localStorage.setItem(key, JSON.stringify(data));
}

export function removeLocalStorage(key) {
  return localStorage.removeItem(key);
}

export function getSessionStorage(key) {
  return JSON.parse(sessionStorage.getItem(key));
}

export function setSessionStorage(key, data) {
  return sessionStorage.setItem(key, JSON.stringify(data));
}

export function removeSessionStorage(key) {
  return sessionStorage.removeItem(key);
}

export function updateLocalStorage(key, data) {
  let obj = JSON.parse(localStorage.getItem(key) || null) || {}
  localStorage.setItem(key, JSON.stringify({ ...obj, ...data }));
}

export function Setcookie(name, value) {
  //设置名称为name,值为value的Cookie
  var expdate = new Date();   //初始化时间
  expdate.setTime(expdate.getTime() + 30 * 60 * 1000);   //时间单位毫秒
  document.cookie = name + "=" + value + ";expires=" + expdate.toGMTString() + ";path=/";
}


export function getCookie(c_name) {
  //判断document.cookie对象里面是否存有cookie
  if (document.cookie.length > 0) {
    let c_start = document.cookie.indexOf(c_name + "=")
    //如果document.cookie对象里面有cookie则查找是否有指定的cookie，如果有则返回指定的cookie值，如果没有则返回空字符串
    if (c_start != -1) {
      let c_start = c_start + c_name.length + 1
      let c_end = document.cookie.indexOf(";", c_start)
      if (c_end == -1) c_end = document.cookie.length
      return unescape(document.cookie.substring(c_start, c_end))
    }
  }
  return null;
}