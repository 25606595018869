import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";
import {SCENE_ROUTER} from './js/constant'
import store from "./store";
import JTDiamondSDK from './js/JTDiamondSDK'
Vue.use(Router);

const  router = new Router({
  mode: "hash",
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
      meta: {
        keepAlive: false // 需要被缓存
      }
    },
    {
      path: "/about",
      name: "about",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "about" */ "./views/About.vue")
    },
    {
      path: "/login",
      name: "login",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "login" */ "./views/login/login.vue"),
      meta: {
        keepAlive: false // 需要被缓存
      }
    },
    {
      path: "/wechat",
      name: "wechat",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "login" */ "./views/login/wechat.vue"),
      meta: {
        keepAlive: false // 需要被缓存
      }
    },
    {
      path: "/my", //我的页面
      name: "my",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "my" */ "./views/my/My.vue"),
      meta: {
        keepAlive: true // 需要被缓存
      }
    },
    {
      path:'/customPage',
      name:'customPage',
      component:() => import("./views/custompage/index.vue"),
      meta:{
        keepAlive:false
      }
    },
    {
      path: "/category", //分类页面
      name: "category",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "category" */ "./views/category/category.vue"),
      meta: {
        keepAlive: true, // 需要被缓存
      }
    },
    {
      path: "/category2", //分类页面
      name: "category2",
      component: () =>
        import(/* webpackChunkName: "category" */ "./views/category/category_copy_5.vue"),
      meta: {
        keepAlive: true // 需要被缓存
      },

    },
    {
      path: "/category4", //分类页面
      name: "category4",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "category" */ "./views/category/category_copy_4.vue"),
      meta: {
        keepAlive: true // 需要被缓存
      }
    },
    {
      path: "/category5", //分类页面
      name: "category5",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "category" */ "./views/category/category_copy_5.vue"),
      meta: {
        keepAlive: true // 需要被缓存
      }
    },
    {
      path: "/details", //详情页面
      name: "details",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "details" */ "./views/category/details.vue"),
      meta: {
        keepAlive: false, //
        animate: true
      }
    },
    {
      path: "/details2", //详情页面
      name: "details2",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "details" */ "./views/category/details2.vue"),
      meta: {
        keepAlive: false, //
        animate: true
      }
    },
    {
      path: "/cash", //现货页面
      name: "cash",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "cash" */ "./views/cash/cash.vue"),
      meta: {
        keepAlive: false //
      }
    },
    {
      path: "/inventory", //库存页面
      name: "inventory",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "inventory" */ "./views/my/inventory.vue")
    },
    {
      path: "/member", //会员页面
      name: "member",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "member" */ "./views/my/member.vue")
    },
    {
      path: "/search", //搜索页面
      name: "search",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "search" */ "./views/search/search.vue"),
      meta: {
        keepAlive: false //
      }
    },
    {
      path: "/searchresult", //搜索结果
      name: "searchresult",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "search" */ "./views/search/searchresult.vue"),
      meta: {
        keepAlive: false // 需要被缓存
      }
    },
    {
      path: "/history", //历史页面
      name: "history",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "search" */ "./views/history/history.vue"),
      meta: {
        keepAlive: true // 需要被缓存
      }
    },
    {
      path: "/vip", //会员页面
      name: "vip",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "search" */ "./views/vip/vip.vue")
    },
    {
      path: "/activitydetail", //活动详情
      name: "activitydetail",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "search" */ "./views/activity/activityDetail.vue"),
      meta: {
        keepAlive: false // 需要被缓存
      }
    },
    {
      path: "/activitylist", //活动列表
      name: "activitylist",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "search" */ "./views/activity/activityList.vue"),
      meta: {
        keepAlive: false // 需要被缓存
      }
    },
    {
      path: "/video", //活动列表
      name: "video",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "search" */ "./views/video/video.vue"),
      meta: {
        keepAlive: true // 需要被缓存
      }
    },
    {
      path: "/videodetail", //活动列表
      name: "videodetail",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "search" */ "./views/video/videoDetail.vue"),
      meta: {
        keepAlive: false // 需要被缓存
      }
    },
    {
      path: "/detailVideo", //活动列表
      name: "detailVideo",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "search" */ "./views/category/detailVideo.vue"),
      meta: {
        keepAlive: false // 需要被缓存
      }
    },
    // {
    //     path: '/detailThree',      //活动列表
    //     name: 'detailThree',
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () => import(/* webpackChunkName: "search" */ './standerMaterialView1/views/Home.vue'),
    //     meta: {
    //         keepAlive: false // 需要被缓存
    //     }
    // },
    // {
    //   path: "/holographic", //活动列表
    //   name: "holographic",
    //   component: () =>
    //     import(/* webpackChunkName: "search" */ "./views/holographic/holographic.vue"),
    //   meta: {
    //     keepAlive: false // 需要被缓存
    //   }
    // },
    {
      path: "/orderList", //订单列表
      name: "orderList",
      component: () => import(/* webpackChunkName: "search" */ "./views/order"),
      meta: {
        keepAlive: false // 需要被缓存
      },
    },
    {
      path: "/orderDetail", //订单列表
      name: "orderDetail",
      component: () =>
        import(/* webpackChunkName: "search" */ "./views/orderDetail"),
      meta: {
        keepAlive: false // 需要被缓存
      }
    },
    {
      path: "/shoppingcart", //分类页面
      name: "shoppingcart",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "category" */ "./views/shoppingCart/shoppingcart.vue"),
      meta: {
        keepAlive: true // 需要被缓存
      }
    },
    {
      path: "/orderCheckout", //订单确认
      name: "orderCheckout",
      component: () =>
        import(/* webpackChunkName: "search" */ "./views/orderCheckout"),
      meta: {
        keepAlive: false // 需要被缓存
      }
    },
    {
        path: "/goldPrice", //今日金价
        name: "goldPrice",
        component: () =>
          import(/* webpackChunkName: "search" */ "./views/goldPrice"),
        meta: {
          keepAlive: false // 需要被缓存
        }
      },
  ]
});
router.beforeEach((to,from,next)=>{
  if(SCENE_ROUTER.indexOf(from.name) != -1 ){
      if(JTDiamondSDK.DisplayModelFin.getInstance()&&JTDiamondSDK.DisplayModelFin.getInstance().dispose){
        JTDiamondSDK.DisplayModelFin.getInstance().dispose()
      }
    next()

  }else{
    next()
  }
 })
 export default router