import {getLocalStorage, setLocalStorage, removeLocalStorage} from '@/utils/storage';

const TokenKey = 'AUTHORIZATION';

export function getToken() {
  return getLocalStorage(TokenKey);
}

export function setToken(token) {
  return setLocalStorage(TokenKey, token);
}

export function removeToken() {
  return removeLocalStorage(TokenKey);
}
