import axios from 'axios'
import $http from '../utils/request.js'
import { getLocalStorage } from "@/utils/storage.js";

const getAppId = () => {
    return getLocalStorage('wxapp_id') || 10013;
}
// let shop_id = getLocalStorage("shop_id");

const getShopId = () => {
    return getLocalStorage('shop_id') || 10020;
}

export const sendGoodBrowseInfo = (goods_id, click_time, view_duration, token, type = 3, wxapp_id = getAppId()) => {
    $http.post(`/index.php?s=/api/count.goods_data/clickGoodsData`, { wxapp_id, token, goods_id,click_time,view_duration,type })
}
export const sendCategoryBrowseInfo = (category_id, click_time, view_duration, token, type = 3, wxapp_id = getAppId()) => {
    $http.post(`/index.php?s=/api/count.goods_data/clickCategoryData`, { wxapp_id, token, category_id,click_time,view_duration,type })
}

//获取二维码跳转目标 是否跳转到门店
export const getJumpSwitch = () => $http.get(`/index.php?s=/api/wxapp/getJumpSwitch&wxapp_id=${getAppId()}&shop_id=${getShopId()}`)


//商品分类列表
export const getCategory = (wxapp_id = getAppId()) => $http.get(`/index.php?s=/api/category/lists&wxapp_id=${wxapp_id}`)
//商品分类列表
export const getCategoryChild = (wxapp_id = getAppId()) => $http.get(`/index.php?s=/api/category/index&wxapp_id=${wxapp_id}`)
//商品列表
export const getList = (page, sortType, sortPrice, category_id, search ,token = "", tags = "", listRows = 24, is_stock = -1,isThree = '',model_version,isHasModel) => $http.get(`/index.php?s=/api/goods/lists&page=${page}&sortType=${sortType}&sortPrice=${sortPrice}&category_id=${category_id}&search=${search}&token=${token}&wxapp_id=${getAppId()}&tags=${tags}&is_stock=${is_stock}&shop_id=${getShopId()?getShopId():0}&listRows=${listRows}&isThree=${isThree}&model_version=${model_version}&isHasModel=${isHasModel}`)
//商品详情
export const getdetail = (goods_id, wxapp_id = getAppId()) => $http.get(`/index.php?s=/api/goods/detail&goods_id=${goods_id}&wxapp_id=${wxapp_id}`)
//推广二维码
export const getposter = (goods_id, wxapp_id = getAppId()) => axios.get(`/index.php?s=/api/goods/poster&goods_id=${goods_id}&wxapp_id=${wxapp_id}`)
//获取登录
export const getWxLoginUrl = (wxapp_id = getAppId()) => $http.get(`/index.php?s=/api/user/getWxLoginUrl&wxapp_id=${wxapp_id}&loginType=largeScreen`)
//扫码登录
export const pcWeixinLogin = (code, state, wxapp_id = getAppId()) => $http.get(`index.php?s=/api/user/pcLogin&code=${code}&state=${state}&wxapp_id=${wxapp_id}&loginType=largeScreen`)
//登录
export const mLogin = (mobile, code, wxapp_id = getAppId()) => $http.get(`/index.php?s=/api/user/login_mobile&wxapp_id=${wxapp_id}&mobile=${mobile}&code=${code}`)
//图片验证码
export const captcha = (size = 20, height = 35, width = 120, length = 3) => $http.get(`/api/captcha?size=${size}&height=${height}&width=${width}&length=${length}`)
//短信验证码
export const code = (mobile, captcha_id, captcha, template = "login") => $http.get(`/api/sms/code?mobile=${mobile}&template=${template}&captcha_id=${captcha_id}&captcha=${captcha}`)
//登录
export const login_mobile = (mobile, password, shop_id, wxapp_id = getAppId()) => $http.post(`/index.php?s=/api/store.user/login`, { mobile, password, wxapp_id })
//根据goodsID获取商品列表
export const getListById = (ids, wxapp_id = getAppId()) => $http.get(`/index.php?s=/api/goods/ids&wxapp_id=${wxapp_id}&ids=${ids}`)
//获取圈层token
export const tokens = (wxapp_id, token) => $http.get(`/api/user/login_abc&wxapp_id=${wxapp_id}&token=${token}`)
//获取圈层token
export const userinfo = (token, shop_id = getShopId(), wxapp_id = getAppId()) => $http.get(`/index.php?s=/api/user/info&wxapp_id=${wxapp_id}&token=${token}`)
//活动首页轮播
export const activeHome = (shop_id = getShopId(), name = "", wxapp_id = getAppId(),type = 2 ) => $http.get(`/index.php?s=/api/pad_active/home&name=${name}&shop_id=${shop_id}&wxapp_id=${wxapp_id}&type=${type}`)
//活动列表
export const activeList = (pad_ad_id, page, wxapp_id = getAppId()) => $http.get(`/index.php?s=/api/pad_active/lists&wxapp_id=${wxapp_id}&page=${page}&pad_ad_id=${pad_ad_id}`)
//活动详情
export const activeDetail = (id, wxapp_id = getAppId()) => $http.get(`/index.php?s=/api/pad_active/detail&wxapp_id=${wxapp_id}&id=${id}`)
//屏幕保护
export const screen = (shop_id = getShopId(), wxapp_id = getAppId()) => $http.get(`/index.php?s=/api/pad_active/screen&wxapp_id=${wxapp_id}`)

//视频列表
export const getVideoList = (wxapp_id = getAppId(), shop_id = getShopId(),type=2) => $http.post(`/index.php?s=/api/pad_active/videos`, { wxapp_id: wxapp_id, shop_id ,type})

//sku列表
export const skuList = (goods_number, is_stock = -1, wxapp_id = getAppId()) => $http.get(`/index.php?s=/api/goods/goodsNumber&wxapp_id=${wxapp_id}&goods_number=${goods_number}&is_stock=${is_stock}`)

//下单
export const padOrder = (token, goods_id, goods_num, goods_remark, lettering, customer_phone, customer_name, sign_image_url, shop_id = getShopId(), wxapp_id = getAppId()) => $http.post(`/index.php?s=/api/order/buyNow?a=ss&goods_id=${goods_id}&goods_num=${goods_num}&goods_sku_id=0&delivery=30&goods_remark=${goods_remark}&lettering=${lettering}&customer_phone=${customer_phone}&customer_name=${customer_name}&sign_image_url=${sign_image_url}`, { token, goods_id, wxapp_id: wxapp_id })
//商家信息
export const orderinfo = (token, shop_id = getShopId(), wxapp_id = getAppId()) => $http.post(`/index.php?s=/api/store.user/info`, { token, wxapp_id: wxapp_id })

//订单列表
export const orderList = (page, token, search, query_date, shop_id = getShopId(), wxapp_id = getAppId()) => $http.get(`/index.php?s=/api/user.order/lists`, { params: { page, token, search, dataType: 'all', query_date, wxapp_id: wxapp_id } })

//修改订单状态
export const orderStatus = (order_id, status, token, shop_id = getShopId(), wxapp_id = getAppId()) => $http.post(`/index.php?s=/api/order.pad_order/orderStatus`, { order_id, status, token, wxapp_id: wxapp_id })

//取消订单
export const orderCancel = (order_id, token, shop_id = getShopId(), wxapp_id = getAppId()) => $http.post(`/index.php?s=/api/order/cancelOrder`, { order_id, token, wxapp_id: wxapp_id })

//加入购物车
export const addCard = (goods_id, goods_num, goods_sku_id, token, wxapp_id = getAppId()) => $http.post(`/index.php?s=/api/cart/add`, { goods_id, goods_num, goods_sku_id, token, wxapp_id: wxapp_id })


//订单详情
export const orderDetail = (order_id, token, shop_id = getShopId(), wxapp_id = getAppId()) => $http.get(`/index.php?s=/api/user.order/detail`, { params: { order_id, token, wxapp_id: wxapp_id } })

export const shoppCarList = (token, wxapp_id = getAppId(),) => $http.get(`/index.php?s=/api/cart/lists&wxapp_id=${wxapp_id}&token=${token}`)


//减少购物车数量
export const minuCard = (goods_id, goods_sku_id, token, wxapp_id = getAppId()) => $http.post(`/index.php?s=/api/cart/sub`, { goods_id, goods_sku_id, token, wxapp_id: wxapp_id })

//删除购物车
export const delateCard = (goods_sku_id, token, wxapp_id = getAppId()) => $http.post(`/index.php?s=/api/cart/delete`, { goods_sku_id, token, wxapp_id: wxapp_id })

//购物车详情
export const orderCardDetail = (cart_ids, token, shop_id = getShopId(), wxapp_id = getAppId()) => $http.get(`/index.php?s=/api/order/cart`, { params: { cart_ids, delivery: 30, token, wxapp_id: wxapp_id } })

//七牛token
export const qiniuToken = (token, wxapp_id = getAppId()) => $http.get(`/index.php?s=/api/upload/get_token`, { params: { token, wxapp_id } })

//购物车下单
export const cardOrder = (cart_ids, token, lettering, goods_remark, customer_phone, customer_name, sign_image_url, wxapp_id = getAppId()) => $http.post(`/index.php?s=/api/order/cart`, { cart_ids, token, lettering, goods_remark, customer_phone, customer_name, sign_image_url, delivery: 30, wxapp_id: wxapp_id })

// export const getCategory = (wxapp_id = getAppId()) => $http.post(`/index.php?s=/api/category/lists`,{wxapp_id:wxapp_id})
// //商品列表
// export const getList = (page, sortType, sortPrice, category_id, search="", tags = "", wxapp_id = getAppId()) => $http.post(`/index.php?s=/api/goods/lists`,{page:page,sortType:sortType,sortPrice:sortPrice,category_id:category_id,search:search,wxapp_id:wxapp_id,tags:tags})
// //商品详情
// export const getdetail = (goods_id, wxapp_id = getAppId()) => $http.post(`/index.php?s=/api/goods/detail`,{goods_id:goods_id,wxapp_id:wxapp_id})
// //推广二维码
// export const getposter = (goods_id, wxapp_id = getAppId()) => axios.post(`/index.php?s=/api/goods/poster`,{goods_id:goods_id,wxapp_id:wxapp_id})
// //登录
// export const mLogin = (mobile, code, wxapp_id = getAppId()) => $http.post(`/index.php?s=/api/user/login_mobile`,{wxapp_id:wxapp_id,mobile:mobile,code:code})
// //图片验证码
// export const captcha = (size = 20, height = 35, width = 120, length = 3) => $http.post(`/api/captcha`,{size:size,height:height,width:width,length:length})
// //短信验证码
// export const code = (mobile, captcha_id, captcha, template = "login") => $http.post(`/api/sms/code`,{mobile:mobile,template:template,captcha_id:captcha_id,captcha:captcha})
// //登录
// export const login_mobile = (mobile, code, wxapp_id = getAppId()) => $http.post(`/index.php?s=/api/user/login_mobile`,{wxapp_id:wxapp_id,mobile:mobile,code:code})
// //根据goodsID获取商品列表
// export const getListById = (ids, wxapp_id = getAppId()) => $http.post(`/index.php?s=/api/goods/ids`,{wxapp_id:wxapp_id,ids:ids})
// //获取圈层token
// export const tokens = (wxapp_id, token) => $http.post(`/api/user/login_abc`,{wxapp_id:wxapp_id,token:token})
// //获取圈层token
// export const userinfo = (token, wxapp_id = getAppId()) => $http.post(`/index.php?s=/api/user/info`,{wxapp_id:wxapp_id,token:token})
// //活动首页轮播
// export const activeHome = (name = "",wxapp_id = getAppId()) => $http.post(`/index.php?s=/api/pad_active/home`,{name:name,wxapp_id:wxapp_id})
// //活动列表
// export const activeList = (pad_ad_id,page,wxapp_id = getAppId()) => $http.post(`/index.php?s=/api/pad_active/lists`,{wxapp_id:wxapp_id,page:page,pad_ad_id:pad_ad_id})
// //活动详情
// export const activeDetail = (id,wxapp_id = getAppId()) => $http.post(`/index.php?s=/api/pad_active/detail`,{wxapp_id:wxapp_id,id:id})

//获取门店详情

export const getDetail = (shop_id = getShopId(), wxapp_id = getAppId()) => $http.get(`/index.php?s=/api/shop/detail`, { params: { shop_id, wxapp_id } })

//获取金价
export const gold_price = (shop_id = getShopId(), wxapp_id = getAppId(),) => $http.get(`/index.php?s=/api/gold_price/detail&wxapp_id=${wxapp_id}&shop_id=${shop_id}`)

// 获取产品配置参数
export const getProductInfo = (goods_number,is_stock,wxapp_id = getAppId()) => {
    return  $http.get(`/index.php?s=/api/goods/goodsNumber&wxapp_id=${wxapp_id}&goods_number=${goods_number}&is_stock=${is_stock}`)
}